body {
    background: radial-gradient(at 98% 69%, rgba(12, 23, 91, 0.91) 0px, transparent 50%), radial-gradient(at 89% 12%, rgba(17, 23, 56, 0.91) 0px, transparent 50%), radial-gradient(at 27% 51%, rgba(12, 20, 71, 0.91) 0px, transparent 50%), radial-gradient(at 82% 85%, rgba(32, 41, 98, 0.91) 0px, transparent 50%), radial-gradient(at 3% 34%, rgba(21, 35, 124, 0.91) 0px, transparent 50%), radial-gradient(at 0% 18%, rgba(55, 66, 117, 1) 0px, transparent 50%), radial-gradient(at 2% 70%, rgba(103, 139, 191, 0.91) 0px, rgba(31, 68, 122, 0.91) 50%);
    font-family: 'Lato', sans-serif;
    overflow: hidden;
}

.ChannelThumbnail {
    border-radius: 60%;
}

.container {
    height: 100vh;
}

.secondcontainer {
    height: 60vh;
    width: 55vw;
    background-color: rgb(255, 255, 255);
    align-items: center;
    margin: 0 auto;
    border-radius: 20px;
    margin-top: 100px;
    text-align: center;
}

.ChannelOverview {
    width: 450px;
    display: inline-block;
    padding-top: 30px;
}

.COLatestVideos {
    width: 500px;
    display: inline-block;
    transform: translateY(-30px);
}

.ChannelOverviewTitle {
    margin: 0 auto;
    width: auto;
    padding-top: 40px;
    padding-bottom: 20px;
}

.ChannelOverviewStatsList {
    list-style-type: none;
    transform: translateX(-20px);
}

.LatestVideoList {
    text-align: left;
    text-decoration: none;
}

.LatestVideosTitle {
    transform: translateX(20px)
}

.FloatContainer {
    padding:20px;
}

.FloatChild {
    width: 50%;
    float: left;
    padding: 20px;
    margin: 0 auto;
    padding-left: 150px;
}

.HomeLink {
    position: absolute;
    transform: translate(20px, -20px);
}

.VideoData {
    padding-top: 15px;
}